import React from 'react';

const Login: React.FC = () => {
  return (
    <>
      <h1>Login/Index</h1>
    </>
  )
}

export default Login;