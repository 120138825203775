import { Layout, Menu, Breadcrumb } from 'antd';
import {
  FileOutlined,
} from '@ant-design/icons';
import { useState } from 'react';

import Termo from './Termo'
import SubMenu from 'antd/lib/menu/SubMenu';

const { Header, Content, Footer, Sider } = Layout;
const Home: React.FC  = () => {
  
  const [state, setState] = useState({
    collapsed: false,
  });

  const [itemMenu, setItemMenu] = useState('1');

  
  const onCollapse = (collapsed: any) => {
    console.log(collapsed);
    setState({ collapsed });
  };

  const { collapsed } = state;

  return (
    <Layout style={{ minHeight: '100vh' }}>
      <Sider collapsible collapsed={collapsed} onCollapse={onCollapse}>
        <div style={{height: '32px', margin: '16px', background: 'rgba(255, 255, 255, 0.3)'}} />
        <Menu theme="dark" defaultSelectedKeys={[itemMenu]} mode="inline">
          <SubMenu key="sub1" icon={<FileOutlined />} title="Arquivos">
              <Menu.Item key="1" onClick={() => setItemMenu('1')} >Termo</Menu.Item>
            </SubMenu>
        </Menu>
      </Sider>
      <Layout className="site-layout">
        <Header className="site-layout-background" style={{ padding: 0 }} />
 
          {(() => {
            if(itemMenu === '1'){
              return (<Termo />);
            }
          })()}

        <Footer style={{ textAlign: 'center' }}> Pedro do Iphone ©2021 Created by <a href="https://github.com/luiseduardosilva/">Luís Eduardo</a></Footer>
      </Layout>
    </Layout>
  );
  
  
}


export default Home;