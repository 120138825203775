import React, { useCallback, useEffect, useState } from 'react';


import { Form, Input, Button, Select, DatePicker,  Layout, Breadcrumb, message, Switch, Spin } from 'antd';

import { LoadingOutlined } from '@ant-design/icons';

import api from '../../../services/api';

const { Content } = Layout;

const Termo: React.FC  = () => {
  
  const [form] = Form.useForm();
  const { Option } = Select;

  const [iphones, setIphones] = useState([]);
  const [colors, setColors] = useState([]);
  const [gigabytes, setGigabytes] = useState([]);
  const [spinning, setSpinning] = useState(false);

  // @Form spinning
  const [spinningIphone, setSpinningIphone] = useState(true);
  const [spinningColor, setSpinningColor] = useState(true);
  const [spinningGb, setSpinningGb] = useState(true);

  const handleGetAllIphones = useCallback( async () => {
    await api.get('api/iphones')
      .then((result: any) => {
        setSpinningIphone(false);
        setIphones(result?.data?.data);
      });
  }, []);


  const handleGetAllIphonesColors = useCallback( async () => {
    await api.get('api/iphones/colors')
      .then((result: any) => {
        setSpinningColor(false);
        setColors(result?.data?.data);
      });
  }, []);
  
  const handleGetAllIphonesGigabytes = useCallback( async () => {
    await api.get('api/iphones/gigabytes')
      .then((result: any) => {
        setSpinningGb(false);
        setGigabytes(result?.data?.data);
      });
  }, []);
  
  useEffect( () => {
    handleGetAllIphones();
    handleGetAllIphonesColors();
    handleGetAllIphonesGigabytes();
  },[]);
  const onFinish = async ( values: any) => {
    setSpinning(true);
    
    function downloadPDF(pdf:any, name: string) {
      // var a = document.createElement("a"); //Create <a>
      // a.href = "data:application/pdf;base64," + pdf; //Image Base64 Goes here
      // a.download = `Termo Garantia - ${name}.pdf`; //File name Here
      // a.click(); //Downloaded file
      const linkSource = `data:application/pdf;base64,${pdf}`;
      const downloadLink = document.createElement("a");
      const fileName = `Termo Garantia - ${name}.pdf`;
      downloadLink.href = linkSource;
      downloadLink.download = fileName;
      downloadLink.click();
    }

    await api.post('api/termo', values)
      .then((e: any) => {
        setSpinning(false);
        message.success('Termo gerado com sucesso');
        const blob = e.data;
        downloadPDF(blob, (values?.name) ?? '')
      }).catch((e) => {
        message.error('Ocorreu um erro ao gerar Termo')
        setSpinning(false)
      });
    
  };

  const onReset = () => {
    form.resetFields();
  };

  const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;

  
  
  return (
    <>
      <Content style={{ margin: '0 16px' }}>
        <Breadcrumb style={{ margin: '16px 0' }}>
          <Breadcrumb.Item>Arquivos</Breadcrumb.Item>
          <Breadcrumb.Item>Termo</Breadcrumb.Item>
        </Breadcrumb>
        <Spin spinning={spinning}  indicator={antIcon} size="large" delay={500} >
          <div style={{ padding: 24, minHeight: 360, background: "#fff" }}>
            <Form  layout='vertical' form={form} name="control-hooks" onFinish={onFinish}>
              <Form.Item name="name" label="Nome" rules={[{ required: true }]}>
                <Input />
              </Form.Item>

              <Form.Item name="cpf" label="CPF" rules={[{ required: true }]}>
                <Input />
              </Form.Item>

              <Spin spinning={spinningIphone}  indicator={antIcon} size="large" delay={500} >
              
              <Form.Item name="phone" label="iPhone" rules={[{ required: true }]}>
                  <Select
                    placeholder="Select a option and change input text above"
                  >
                    {
                      iphones.map((iphone: any) => {
                        return (<Option value={iphone?.id}> {iphone?.name} </Option>)
                      })
                    }
                  </Select>
                </Form.Item>

              </Spin>
              <Spin spinning={spinningColor}  indicator={antIcon} size="large" delay={500} >
              
                <Form.Item name="color" label="Cor" rules={[{ required: true }]}>
                  <Select
                    placeholder="Select a option and change input text above"
                  >
                    {
                      colors.map((color: any) => {
                        return (<Option value={color?.id}> {color?.color} </Option>)
                      })
                    }

                  </Select>
                </Form.Item>

              </Spin>

              <Form.Item name="imei" label="IMEI" rules={[{ required: false }]}>
                <Input />
              </Form.Item>

              <Spin spinning={spinningGb}  indicator={antIcon} size="large" delay={500} >
              
                <Form.Item name="gb" label="GB" rules={[{ required: true }]}>
                  <Select
                    placeholder="Select a option and change input text above"
                  >
                    {
                      gigabytes.map((gb: any) => {
                        return (<Option value={gb?.id}> {gb?.gigabyte} </Option>)
                      })
                    }
                  </Select>
                </Form.Item>

              </Spin>
              
              <Form.Item name="dtSale" label="Data da venda" rules={[{ required: true }]}>
                <DatePicker />
              </Form.Item>
              <Form.Item name="garantia" label="Garantia" rules={[{ required: true }]}>
                <Select
                  placeholder="Selecione a quantidade de dias da garantia"
                >
                  <Option value="30"> 30 </Option>
                  <Option value="60"> 60 </Option>
                  <Option value="90"> 90 </Option>
                  <Option value="180"> 180 </Option>
                </Select>
              </Form.Item>

              <Form.Item name="vitrine" label="Vitrine?">
                <Switch defaultChecked={false} />
              </Form.Item>
              <Form.Item style={{width: '100%'}}>
                <Button type="primary" htmlType="submit">
                  Gerar Termo
                </Button>
                <Button htmlType="button" onClick={onReset}>
                  Limpar
                </Button>
              </Form.Item>
            </Form>
          </div>
        </Spin>
        
      </Content>
    </>
  )
}


export default Termo;